// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Events from "../../../libs/Events.res.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as CartItem from "./CartItem.res.js";
import * as Keyboard from "../../../libs/Keyboard.res.js";
import * as IconClose from "../../../styleguide/icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as AddToCartModalScss from "./AddToCartModal.scss";

var css = AddToCartModalScss;

function AddToCartModal(props) {
  var __mobile = props.mobile;
  var close = props.close;
  var toggleCart = props.toggleCart;
  var product = props.product;
  var mobile = __mobile !== undefined ? __mobile : false;
  React.useEffect((function () {
          return Events.Subscriptions.subscribeToKeyDown(function ($$event) {
                      Keyboard.Dom.onEsc($$event, close);
                    });
        }), []);
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: "The product was added to your cart",
                                    className: css.title
                                  }),
                              JsxRuntime.jsx(CartItem.make, {
                                    providerName: product.providerName,
                                    name: product.name,
                                    image: product.providerLogo,
                                    category: product.category,
                                    price: product.pricePerMonth,
                                    qty: props.currentItemQty,
                                    updateQty: props.updateQty,
                                    saleEnd: product.endDate,
                                    userLoginStatus: props.userLoginStatus
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Button.make, {
                                            size: mobile ? "SM" : "MD",
                                            color: "Primary",
                                            expanded: false,
                                            submit: false,
                                            className: css.buttonItem,
                                            onClick: (function (param) {
                                                toggleCart();
                                              }),
                                            children: "View Shopping Cart"
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: mobile ? "SM" : "MD",
                                            color: "Gray",
                                            expanded: false,
                                            submit: false,
                                            className: css.buttonItem,
                                            onClick: (function (param) {
                                                close();
                                              }),
                                            children: "Continue Shopping"
                                          })
                                    ],
                                    className: css.cartButton
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: Cx.cx([css.sideModal]),
                      id: "sideModal"
                    })
              ],
              className: Cx.cx([css.sideModalContainer]),
              id: "sideModalContainer"
            });
}

var make = AddToCartModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
