// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Char from "../../../libs/Char.res.js";
import * as Badge from "../../../styleguide/components/Badge/Badge.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as IconRFP from "../../../styleguide/icons/IconRFP.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Tooltip from "../../../styleguide/components/Tooltip/Tooltip.res.js";
import * as LazyImage from "../../../styleguide/components/LazyImage/LazyImage.res.js";
import * as Cloudinary from "../../../libs/Cloudinary.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as IconSolidCart from "../../../styleguide/icons/IconSolidCart.res.js";
import * as AddToCartModal from "../../product-detail/cart-modal/AddToCartModal.res.js";
import * as Routes_Project from "../../../routes/common/Routes_Project.res.js";
import Format from "date-fns/format";
import * as ProductBandwidth from "../../../models/ProductBandwidth.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProductTileScss from "./ProductTile.scss";
import * as ProductIpAllocation from "../../../models/ProductIpAllocation.res.js";
import * as SignInModalContainer from "../../../styleguide/modals/SignInModal/SignInModalContainer.res.js";

var css = ProductTileScss;

function buildUrl(src) {
  return Cloudinary.Url.build("Auto", {
              hd: {
                TAG: "Height",
                _0: 120
              },
              tl: /* [] */0
            }, src);
}

var Helpers = {
  buildUrl: buildUrl
};

function reducer(state, action) {
  switch (action.TAG) {
    case "ToggleSignInModal" :
        return {
                signInModal: action._0,
                addToCartModal: state.addToCartModal,
                currentItemQty: state.currentItemQty
              };
    case "ToggleAddToCartModal" :
        return {
                signInModal: state.signInModal,
                addToCartModal: action._0,
                currentItemQty: state.currentItemQty
              };
    case "UpdateCurrentItemQty" :
        return {
                signInModal: state.signInModal,
                addToCartModal: state.addToCartModal,
                currentItemQty: action._0
              };
    
  }
}

var initialState = {
  signInModal: "Hidden",
  addToCartModal: "Hidden",
  currentItemQty: 1
};

function ProductTile(props) {
  var setSliderStyle = props.setSliderStyle;
  var __mobile = props.mobile;
  var __toggleCart = props.toggleCart;
  var __setCartQty = props.setCartQty;
  var __tooltipPosition = props.tooltipPosition;
  var userLoginStatus = props.userLoginStatus;
  var __deferImage = props.deferImage;
  var __className = props.className;
  var product = props.product;
  var className = __className !== undefined ? __className : "";
  var deferImage = __deferImage !== undefined ? __deferImage : false;
  var tooltipPosition = __tooltipPosition !== undefined ? __tooltipPosition : "Bottom";
  var setCartQty = __setCartQty !== undefined ? __setCartQty : (function (prim) {
        
      });
  var toggleCart = __toggleCart !== undefined ? __toggleCart : (function (prim) {
        
      });
  var mobile = __mobile !== undefined ? __mobile : false;
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var setSliderStyleHandler = setSliderStyle !== undefined ? setSliderStyle : (function (param) {
        
      });
  var toggleSignInModal = function (toggle) {
    if (toggle === "Shown") {
      setSliderStyleHandler("Hidden");
    } else {
      setSliderStyleHandler("Shown");
    }
    dispatch({
          TAG: "ToggleSignInModal",
          _0: toggle
        });
  };
  var addToCart = function (qty) {
    $$Promise.wait(Api.addToCart(qty, product.id), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "ProductTile",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProductTile.make"
                        }, "ProductTile::AddToCart::Error", [
                          "Error",
                          x._0
                        ]);
            }
            var res = x._0;
            setCartQty(res.cartQty);
            dispatch({
                  TAG: "UpdateCurrentItemQty",
                  _0: res.productQty
                });
            if (qty === 0) {
              return dispatch({
                          TAG: "ToggleAddToCartModal",
                          _0: "Hidden"
                        });
            }
            
          }));
  };
  var toggleAddToCartModal = function (status) {
    if (status === "Shown") {
      setSliderStyleHandler("Hidden");
      addToCart(1);
    } else {
      setSliderStyleHandler("Shown");
    }
    dispatch({
          TAG: "ToggleAddToCartModal",
          _0: status
        });
  };
  var openCartList = function () {
    dispatch({
          TAG: "ToggleAddToCartModal",
          _0: "Hidden"
        });
    toggleCart();
  };
  var match$1 = product.category;
  var tmp;
  switch (match$1) {
    case "cloud" :
        tmp = "Cloud";
        break;
    case "colocation" :
        tmp = "Colocation";
        break;
    default:
      tmp = "Default";
  }
  var x = product.totalPowerKw;
  var tmp$1;
  tmp$1 = userLoginStatus === "LoggedIn" ? JsxRuntime.jsxs("div", {
          children: [
            "$" + String(product.pricePerMonth),
            JsxRuntime.jsx("span", {
                  children: "/month",
                  className: css.perMonth
                })
          ]
        }) : JsxRuntime.jsx("div", {
          children: JsxRuntime.jsx(Control.make, {
                onClick: (function ($$event) {
                    $$event.preventDefault();
                    toggleSignInModal("Shown");
                  }),
                children: "Sign In to See Pricing"
              }),
          className: css.signInMessage
        });
  var match$2 = state.signInModal;
  var match$3 = state.addToCartModal;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("a", {
                      children: [
                        product.isExclusiveOffer ? JsxRuntime.jsx("div", {
                                children: "Exclusive",
                                className: css.exclusiveBanner
                              }) : null,
                        JsxRuntime.jsx("div", {
                              children: deferImage ? JsxRuntime.jsx(LazyImage.make, {
                                      src: buildUrl(product.providerLogo),
                                      height: 100,
                                      className: css.logo
                                    }) : JsxRuntime.jsx("img", {
                                      className: css.logo,
                                      src: buildUrl(product.providerLogo)
                                    }),
                              className: css.logoContainer
                            }),
                        JsxRuntime.jsx(Badge.make, {
                              badgeType: tmp,
                              className: css.productTag,
                              children: $$String.capitalize_ascii(product.category)
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("div", {
                                    children: product.name,
                                    className: css.overflow
                                  }),
                              className: css.productName
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("span", {
                                            children: product.location.city + (", " + product.location.state)
                                          }),
                                      JsxRuntime.jsx(Char.Middot.make, {}),
                                      JsxRuntime.jsx("span", {
                                            children: product.subcategory
                                          }),
                                      JsxRuntime.jsx(Char.Middot.make, {}),
                                      x !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: String(x) + " kW"
                                                    }),
                                                JsxRuntime.jsx(Char.Middot.make, {})
                                              ]
                                            }) : null,
                                      JsxRuntime.jsx("span", {
                                            children: product.powerCircuit
                                          }),
                                      JsxRuntime.jsx(Char.Middot.make, {}),
                                      JsxRuntime.jsx("span", {
                                            children: ProductBandwidth.toLabel(product.bandwidth)
                                          }),
                                      JsxRuntime.jsx(Char.Middot.make, {}),
                                      JsxRuntime.jsx("span", {
                                            children: ProductIpAllocation.toLabel(product.ipAllocation)
                                          })
                                    ],
                                    className: css.overflow
                                  }),
                              className: css.productInfo
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        tmp$1,
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("span", {
                                                      children: "Sale ends on "
                                                    }),
                                                Format(product.endDate, "MMM d")
                                              ],
                                              className: css.saleEndDate
                                            })
                                      ],
                                      className: css.pricing
                                    }),
                                JsxRuntime.jsx(Tooltip.make, {
                                      content: JsxRuntime.jsx("div", {
                                            children: "Customize Requirements. Start an RFP and get a proposal for your custom requirements.",
                                            className: css.tooltipText
                                          }),
                                      position: tooltipPosition,
                                      className: css.productTileTooltip,
                                      tooltipContainerClass: Cx.cx([
                                            css.tooltipContainer,
                                            css.tooltipContainerRFP
                                          ]),
                                      children: JsxRuntime.jsx(Button.make, {
                                            size: "XS",
                                            color: "Primary",
                                            buttonClassName: css.buttonRfp,
                                            onClick: (function (param) {
                                                Url.visit(Routes_Project.Product.Colocation.$$new(product.id));
                                              }),
                                            children: JsxRuntime.jsx(IconRFP.make, {
                                                  title: "",
                                                  size: "SM"
                                                })
                                          })
                                    }),
                                JsxRuntime.jsx(Tooltip.make, {
                                      content: JsxRuntime.jsx("div", {
                                            children: "Add to Cart. No payment required",
                                            className: css.tooltipText
                                          }),
                                      position: tooltipPosition,
                                      className: css.productTileTooltip,
                                      tooltipContainerClass: css.tooltipContainer,
                                      children: JsxRuntime.jsx(Button.make, {
                                            size: "XS",
                                            color: "Teal",
                                            buttonClassName: css.buttonCart,
                                            onClick: (function ($$event) {
                                                $$event.preventDefault();
                                                toggleAddToCartModal("Shown");
                                              }),
                                            children: JsxRuntime.jsx(IconSolidCart.make, {
                                                  title: "",
                                                  size: "SM",
                                                  color: "White"
                                                })
                                          })
                                    })
                              ],
                              className: css.tileFooter
                            })
                      ],
                      className: Cx.cx([
                            css.tile,
                            className
                          ]),
                      href: product.url,
                      target: "_blank"
                    }),
                match$2 === "Shown" ? JsxRuntime.jsx(SignInModalContainer.make, {
                        close: (function () {
                            toggleSignInModal("Hidden");
                          }),
                        callback: (function () {
                            window.location.reload();
                          }),
                        setUserData: props.setUserData,
                        subTitle: "Sign In to see product prices"
                      }) : null,
                match$3 === "Shown" ? JsxRuntime.jsx(AddToCartModal.make, {
                        product: product,
                        toggleCart: (function () {
                            openCartList();
                          }),
                        close: (function () {
                            toggleAddToCartModal("Hidden");
                          }),
                        currentItemQty: state.currentItemQty,
                        updateQty: (function (qty) {
                            addToCart(qty);
                          }),
                        mobile: mobile,
                        userLoginStatus: userLoginStatus
                      }) : null
              ]
            });
}

var make = ProductTile;

var $$default = ProductTile;

export {
  css ,
  Helpers ,
  reducer ,
  initialState ,
  make ,
  $$default as default,
}
/* css Not a pure module */
