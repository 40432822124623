// Generated by ReScript, PLEASE EDIT WITH CARE


function toLabel(x) {
  if (x === "Not Included") {
    return "Internet not included";
  } else {
    return x;
  }
}

export {
  toLabel ,
}
/* No side effect */
